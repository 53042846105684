
export const DashboardFilterFields = [
   
    {
        category: "dashboardMain",
        label: "Start Date",
        inputType: "date",
        inForm: true,
        name: "FromDate",
        wrapperClass: "col-6"
    },
    {
        category: "dashboardMain",
        label: "End Date",
        inputType: "date",
        inForm: true,
        name: "ToDate",
        wrapperClass: "col-6"
    },
    {
        category: "dashboardMain",
        label: "Select Thematic Area",
        placeholder: "Select Thematic Area",
        name: "ThematicArea.Id",
        inputType: "async-dropdown",
        url: "/ThematicArea/GetAll",
        searchAttrib: "name",
        id: "ThematicArea.Id",
        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "asyncdropdown",
        inForm: true,
        wrapperClass: "col-md-12",
        isMulti: false,
    },
    {
        category: "dashboardMain",
        label: "Select SDG",
        placeholder: "Select SDG",
        name: "SustainableDevelopmentGoal.Id",
        inputType: "async-dropdown",
        url: "/SDG/GetAll",
        searchAttrib: "name",
        id: "SustainableDevelopmentGoal.Id",
        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "asyncdropdown",
        inForm: true,
        wrapperClass: "col-md-12",
        isMulti: false
    },{
        label: "District",
        placeholder: "Enter District",
        name: "District.Id",
        id: "District.Id ",
        inputType: "async-dropdown",
        url: "/District/GetAll",
        searchAttrib: "name",
        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "asyncdropdown",
        inForm: true,
        wrapperClass: "col-md-12",
        isMulti: false,
        inResult: true,
      },
    {
        category: "dashboardMain",
        label: "Select Project",
        placeholder: "Select Project",
        name: "Project.Id",
        inputType: "async-dropdown",
        url: "/project/GetAll?DisablePagination=true",
        searchAttrib: "title",
        id: "Project.Id",
        labelAttribute: "title",
        valueAttribute: "id",
        onChangeMethod: "asyncdropdown",
        inForm: true,
        wrapperClass: "col-md-12",
        isMulti: false
    },

    
    {
        category:"dashboardMain",
        inputType: "h4",
        children: "",
        inForm: true,
        wrapperClass: "col-12",
    },

    {
        inputType: "button",
        buttonType: "submit",
        children: "Search",
        inSearch: true,
        wrapperClass: "btn-wrapper ml-auto mt-2",
    },
];
