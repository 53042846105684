import React from 'react';
import { Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import { getFormatedDate } from '../../Utility';
import { baseUrl } from '../../redux';

const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontSize: 10,
    },
    section: {
        marginBottom: 12,
    },
    title: {
        fontSize: 16,
        marginBottom: 12,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    subtitle: {
        fontSize: 12,
        marginBottom: 8,
        fontWeight: 'bold',
    },
    text: {
        fontSize: 10,
        marginBottom: 4,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
       
    },
    label: {
        width: '25%',
        fontWeight: 'bold',
        backgroundColor: 'lightgray',
        padding: 4,
    },
    value: {
        width: '75%',
        padding: 4,
        borderStyle: 'solid',
        borderColor: 'grey',
        borderWidth: 1,
      
    },
    image: {
        width: '80px',
        height: '80px',
        objectFit: 'cover',
        marginRight: 8,
    },
    imagesContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 12,
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginBottom: 10,
    },
    tableRow: {

        flexDirection: "row",
    },
    tableCol: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 5,
    },
    tableCell: {

        marginTop: 5,
        fontSize: 10,
    },
});

const MonitoringVisitReportPDF = ({ data={} }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Text style={styles.title}>Monitoring Visit Report</Text>

                <View style={styles.row}>
                    <Text style={styles.label}>Project:</Text>
                    <Text style={styles.value}>{data?.project?.name}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>Start Date:</Text>
                    <Text style={styles.value}>{getFormatedDate(data?.projectStartDate)}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>End Date:</Text>
                    <Text style={styles.value}>{getFormatedDate(data?.projectEndDate)}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>Reporting Person:</Text>
                    <Text style={styles.value}>{data?.reportingPerson?.name}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>Date of Monitoring Visit:</Text>
                    <Text style={styles.value}>{getFormatedDate(data?.dateOfMonitoring)}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>Location of Visit:</Text>
                    <Text style={styles.value}>{data?.locationOfVisit}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>Visit Objectives:</Text>
                    <Text style={styles.value}>{data?.visitObjectives}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>Methodology Adopted:</Text>
                    <Text style={styles.value}>{data?.methodologyAdopted}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>Project Result/Output:</Text>
                    <Text style={styles.value}>{data?.projectResult}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>Key Observations:</Text>
                    <Text style={styles.value}>{data?.keyObservation}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>Best Practices:</Text>
                    <Text style={styles.value}>{data?.bestPractices}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>Key Challenges/Risks:</Text>
                    <Text style={styles.value}>{data?.keyChallenges}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>Recommendations/Suggestions:</Text>
                    <Text style={styles.value}>{data?.recommendations}</Text>
                </View>
                <Text style={styles.subtitle}>Attachments:</Text>
                <View style={styles.imagesContainer}>
                    {data?.attachments && data?.attachments.length > 0 ? (
                        data?.attachments.map((img, index) => (
                            <View key={index}>
                                <Image
                                    src={baseUrl + img?.url}
                                    style={styles.image}
                                    alt="attachment"
                                />
                            </View>
                        ))
                    ) : (
                        <Text>No Images Added</Text>
                    )}
                </View>
            </View>
            <View style={styles.section}>
                <Text style={styles.title}>Key Activities</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>S.No.</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Activity Name</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Planned Target</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Achieved</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Remarks</Text>
                        </View>
                    </View>
                    {data?.keyActivities?.map((activity, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{index + 1}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{activity?.activity?.name}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{activity?.plannedTarget}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{activity?.achievedTarget}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{activity?.remarks}</Text>
                            </View>
                        </View>
                    ))}
                </View>
            </View>

            {/* Debrief Table */}
            <View style={styles.section}>
                <Text style={styles.title}>Debrief</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Parent ID</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>ID</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Key </Text>
                            <Text style={styles.tableCell}>Challenge</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>DeBrief Memo</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Response</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Action Point</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Timeline</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Date Of</Text>
                            <Text style={styles.tableCell}>DeBrief</Text>
                        </View>


                    </View>
                    {/* keyChallenge: 'wanted to check ',
        deBriefMemo: 'point are raised ',
        response: '120 inute',
        actionPoint: 'work on well  details and correction',
        timeline: '2 weeks',
        dateOfDeBrief: '2024-08-19T00:00:00',
        parentDebrief: null */}
                    {data?.deBriefs?.map((debrief, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{debrief?.parentDebrief?.id}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{debrief?.id}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{debrief?.keyChallenge}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{debrief?.deBriefMemo}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{debrief?.response}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{debrief?.actionPoint}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{debrief?.timeline}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{getFormatedDate(debrief?.dateOfDeBrief)}</Text>
                            </View>

                        </View>
                    ))}
                </View>
            </View>
        </Page>
    </Document>
);

export default MonitoringVisitReportPDF;
